<template>
  <Row type="flex" :gutter="30">
    <Col
        v-if="orderType === 'both' || orderType === 'buy'"
        :xs="{ span: 24 }"
        :lg="{ span: 12 }"
    >
      <Form ref="buyForm" :model="buy">
        <FormItem prop="limitPrice">
          <label class="before" :style="inputLabelStyle">{{
              $t("exchange.buyPrice")
            }}</label>
          <Input
              @on-keyup="handleLimitPrice('buy')"
              v-model="buy.limitPrice"
              placeholder="0"
          >
            <span slot="prepend">
              {{ currentCoin.base }}
            </span>
          </Input>
          <!--          <label class="after">{{ currentCoin.base }}</label>-->
        </FormItem>
        <FormItem prop="limitAmount">
          <label class="before" :style="inputLabelStyle">{{ $t("exchange.buyAmount") }}</label>
          <Input
              @on-keyup="handleLimitAmount('buy')"
              v-model="buy.limitAmount"
              placeholder="0"
          >
            <span slot="prepend">
              {{ currentCoin.coin }}
            </span>
          </Input>
          <!--          <label class="after">{{ currentCoin.coin }}</label>-->
        </FormItem>
        <FormItem class="slider-wrap" prop="sliderPercent">
          <Slider
              class="slider-buy"
              v-model="buy.sliderPercent"
              :step="1"
              show-tip="always"
              :tip-format="tipFormat"
              :disabled="buySliderDisabled"
              @on-change="handleBuySlider"
          ></Slider>
          <div
              class="slider-stop"
              :class="{ 'slider-stop-enable': !buySliderDisabled }"
              v-for="item in sliderStep"
              :key="`${item}-buy`"
              :style="'left: ' + item + '%;'"
              @click="updateStepper('buy', item, buySliderDisabled)"
          >
            <div
                class="slider-block"
                :class="{ 'buy-slider-block-active': buy.sliderPercent > item }"
            ></div>
          </div>
        </FormItem>

<!--        <FormItem prop="totalAmount" class="mt-8 mb-2">-->
<!--          <label class="before" :style="inputLabelStyle">{{ $t("exchange.total") }}</label>-->
<!--          <Input-->
<!--              @on-keyup="handleLimitTotal('buy')"-->
<!--              v-model="buy.totalLimit"-->
<!--              placeholder="0"-->
<!--          >-->
<!--            <span slot="prepend">-->
<!--              USDT-->
<!--            </span>-->
<!--          </Input>-->
<!--          &lt;!&ndash;          <label class="after">{{ currentCoin.coin }}</label>&ndash;&gt;-->
<!--        </FormItem>-->
        <div class="d-flex justify-between align-center font-Regular-12 text-grey mb-8">
          {{ $t("exchange.estimatedReceipt") }}
          <p class="ltr">
            <img class="mr-2" src="@/assets/images/exchange/nearAmount.svg" />
            <span style="color: #FFFFFF">{{
              (removeThousand(buy.limitAmount) - (removeThousand(buy.limitAmount) * takerFee)) | numberWithCommas(toFloor(coinScale))
            }}
            </span>
            <span style="color: #FFFFFF">{{ currentCoin.coin }}</span>

          </p>
        </div>
        <Button
            :loading="loadingAskLimitButton"
            :disabled="!enableLimitBuy || !isExchangeable || noBaseCoins"
            class="pt-2 pb-2 font-Regular-12 text-white fill-width"
            :class="enableLimitBuy && isExchangeable && !noBaseCoins ? 'bg-green' : 'bg-gray'"
            @click="buyWithLimitPrice"
        >{{ $t("exchange.buy") }} ({{ currentCoin.coin }})
        </Button>
        <!--        <Button v-else class="bg-gray pt-2 pb-2 font-Regular-12 text-white fill-width">-->
        <!--          {{ $t("exchange.buyin") }} ({{ currentCoin.coin }})-->
        <!--        </Button>-->
      </Form>
    </Col>

    <Col
        v-if="orderType === 'both' || orderType === 'sell'"
        :xs="{ span: 24 }"
        :lg="{ span: 12 }"
    >
      <Form ref="sellForm" :model="sell">
        <FormItem prop="limitPrice">
          <label class="before" :style="inputLabelStyle">{{ $t("exchange.sellPrice") }}</label>
          <Input
              @on-keyup="handleLimitPrice('sell')"
              v-model="sell.limitPrice"
              placeholder="0"
          >
            <span slot="prepend">
              {{ currentCoin.base }}
            </span>
          </Input>
          <!--          <label class="after">{{ currentCoin.base }}</label>-->
        </FormItem>
        <FormItem prop="limitAmount">
          <label class="before" :style="inputLabelStyle">{{ $t("exchange.sellAmount") }}</label>
          <Input
              @on-keyup="handleLimitAmount('sell')"
              v-model="sell.limitAmount"
              placeholder="0"
          >
            <span slot="prepend">
              {{ currentCoin.coin }}
            </span>
          </Input>
          <!--          <label class="after">{{ currentCoin.coin }}</label>-->
        </FormItem>
        <FormItem class="slider-wrap" prop="sliderPercent">
          <Slider
              class="silder-sell"
              v-model="sell.sliderPercent"
              :step="1"
              show-tip="always"
              :tip-format="tipFormat"
              :disabled="sellSliderDisabled"
              @on-change="handleSellSlider"
          ></Slider>
          <div
              class="slider-stop"
              :class="{ 'slider-stop-enable': !sellSliderDisabled }"
              v-for="item in sliderStep"
              :key="`${item}-sell`"
              :style="'left: ' + item + '%;'"
              @click="updateStepper('sell', item, sellSliderDisabled)"
          >
            <div
                class="slider-block"
                :class="{ 'sell-slider-block-active': sell.sliderPercent > item }"
            ></div>
          </div>
        </FormItem>

<!--        <FormItem prop="totalAmount" class="mt-8 mb-2">-->
<!--          <label class="before" :style="inputLabelStyle">{{ $t("exchange.total") }}</label>-->
<!--          <Input-->
<!--              @on-keyup="handleLimitTotal('sell')"-->
<!--              v-model="sell.totalLimit"-->
<!--              placeholder="0"-->
<!--          >-->
<!--            <span slot="prepend">-->
<!--              USDT-->
<!--            </span>-->
<!--          </Input>-->
<!--          &lt;!&ndash;          <label class="after">{{ currentCoin.coin }}</label>&ndash;&gt;-->
<!--        </FormItem>-->
        <div class="d-flex justify-between align-center font-Regular-12 text-grey mb-8">
          {{ $t("exchange.estimatedReceipt") }}
          <p class="ltr">
            <img class="mr-2" src="@/assets/images/exchange/nearAmount.svg" />
            <span style="color: #FFFFFF">{{
                ((removeThousand(sell.limitAmount) - (removeThousand(sell.limitAmount) * takerFee)) * removeThousand(sell.limitPrice)) | numberWithCommas(toFloor(baseCoinScale))
              }}
            </span>
            <span style="color: #FFFFFF">{{ currentCoin.base }}</span>

          </p>
        </div>
        <Button
            :loading="loadingBidLimitButton"
            :disabled="!enableLimitSell || !isExchangeable || noBaseCoins"
            class="pt-2 pb-2 font-Regular-12 text-white fill-width"
            :class="enableLimitSell && isExchangeable && !noBaseCoins ? 'bg-red' : 'bg-gray'"
            @click="sellLimitPrice"
        >{{ $t("exchange.sell") }} ({{ currentCoin.coin }})
        </Button>
        <!--        <Button v-else class="bg-gray pt-2 pb-2 font-Regular-12 text-white fill-width">-->
        <!--          {{ $t("exchange.sellout") }} ({{ currentCoin.coin }})-->
        <!--        </Button>-->
      </Form>
    </Col>
  </Row>
</template>

<script>
import {handleInput, removeThousand} from "@/utiles/numericValidations";
import {mapActions} from "vuex";

export default {
  name: "LimitOrderForm",
  props: {
    takerFee: {
      type: Number,
      required: true,
    },
    enableLimitBuy: {
      type: Number,
      required: true,
    },
    enableLimitSell: {
      type: Number,
      required: true,
    },
    orderType: {
      type: String,
      required: true,
    },
    currentCoin: {
      type: Object,
      required: true,
    },
    baseCoinScale: {
      type: Number,
      required: true,
    },
    coinScale: {
      type: Number,
      required: true,
    },
    wallet: {
      type: Object,
      required: true,
    },
    platesPrice: {
      type: String,
    },
    isExchangeable: {
      type: Boolean,
      required: true,
    },
    noBaseCoins: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      sell: {
        limitPrice: "",
        limitAmount: "",
        limitTurnover: 0.0,
        sliderPercent: 0,
      },
      buy: {
        limitPrice: "",
        limitAmount: "",
        limitTurnover: 0.0,
        sliderPercent: 0,
      },
      sliderStep: [25, 50, 75, 100],
      loadingBidLimitButton: false,
      loadingAskLimitButton: false,
      isUseBHB: false,
    };
  },
  watch: {
    platesPrice(val, oldVal) {
      if (val !== oldVal) {
        this.resetForms();
        this.setPrices();
      }
    },
  },
  computed: {
    sellSliderDisabled() {
      let account = this.wallet.coin;
      let min = this.toFloor(1 / Math.pow(10, this.coinScale));
      return account < min || !this.enableLimitSell || !this.isExchangeable;
    },
    buySliderDisabled() {
      let account = this.wallet.base;
      let min = this.toFloor(1 / Math.pow(10, this.baseCoinScale));
      return account < min || !this.enableLimitBuy || !this.isExchangeable;
    },
    inputLabelStyle() {
      return {
        right: this.isLocaleLTR ? '10px' : 'unset',
        left: this.isLocaleLTR ? 'unset' : '10px'
      };
    }
  },
  mounted() {
    this.resetForms();
    this.setPrices();
  },
  methods: {
    removeThousand,
    ...mapActions({
      addOrder: "exchange/ADD_ORDER_SERVICE",
    }),
    resetForms() {
      if (this.orderType === "both") {
        this.$refs.buyForm.resetFields();
        this.$refs.sellForm.resetFields();
      } else {
        this.$refs[`${this.orderType}Form`].resetFields();
      }
    },
    setPrices() {
      this.sell.limitPrice = handleInput(this.platesPrice, this.baseCoinScale);
      this.buy.limitPrice = handleInput(this.platesPrice, this.baseCoinScale);
    },
    handleLimitPrice(key) {
      this[key].limitPrice = handleInput(
          this[key].limitPrice,
          this.baseCoinScale
      );
      const limitPrice = removeThousand(this[key].limitPrice);
      const limitAmount = removeThousand(this[key].limitAmount);

      this[key].limitTurnover = this.toFloor(limitPrice.mul(limitAmount));
      this.setSliderPercent(key, limitPrice, limitAmount);
    },
    handleLimitAmount(key) {
      this[key].limitAmount = handleInput(
          this[key].limitAmount,
          this.coinScale
      );
      const limitPrice = removeThousand(this[key].limitPrice);
      const limitAmount = removeThousand(this[key].limitAmount);

      this[key].limitTurnover = this.toFloor(limitAmount.mul(limitPrice));
      this.setSliderPercent(key, limitPrice, limitAmount);
    },
    handleLimitTotal(key) {
      this[key].totalLimit = handleInput(
          this[key].totalLimit,
          this.coinScale
      );
      // const limitPrice = removeThousand(this[key].limitPrice);
      // const limitAmount = removeThousand(this[key].limitAmount);
      //
      // this[key].limitTurnover = this.toFloor(limitAmount.mul(limitPrice));
      // this.setSliderPercent(key, limitPrice, limitAmount);
    },
    setSliderPercent(key, limitPrice, limitAmount) {
      let account = this.wallet.coin;
      let turnOver = limitAmount;
      if (key === "buy") {
        account = this.wallet.base;
        turnOver = limitPrice.mul(limitAmount);
      }
      this[key].sliderPercent =
          account > 0 && parseFloat(limitPrice) > 0 && parseFloat(limitAmount) > 0
              ? parseFloat(turnOver.div(account).mul(100))
              : 0;
    },
    handleSellSlider(val) {
      this.sell.limitAmount = handleInput(
          this.wallet.coin.mul(val).mul(0.01),
          this.coinScale
      );
      this.sell.limitTurnover = this.toFloor(
          removeThousand(this.sell.limitAmount).mul(
              removeThousand(this.sell.limitPrice)
          )
      );
    },
    handleBuySlider(val) {
      let price = removeThousand(this.buy.limitPrice),
          account = this.wallet.base,
          amount = 0;
      if (price > 0) {
        amount = account.div(price).mul(val).mul(0.01);
      }
      this.buy.limitAmount = handleInput(amount, this.coinScale);
      this.buy.limitTurnover = this.toFloor(
          removeThousand(this.buy.limitAmount).mul(
              removeThousand(this.buy.limitPrice)
          )
      );
    },
    updateStepper(key, val, disabled) {
      if (!disabled) {
        this[key].sliderPercent = val;
        if (key === "buy") this.handleBuySlider(val);
        else this.handleSellSlider(val);
      }
    },
    tipFormat(val) {
      return val + "%";
    },
    sellLimitPrice() {
      this.loadingBidLimitButton = true;
      const limitAmount = removeThousand(this.sell.limitAmount);
      const limitPrice = removeThousand(this.sell.limitPrice);

      if (limitAmount === "") {
        this.$toast.error(this.$t("exchange.enterSellAmount"));
        this.loadingBidLimitButton = false;
        return;
      }
      if (limitPrice === "") {
        this.$toast.error(this.$t("exchange.enterSellPrice"));
        this.loadingBidLimitButton = false;
        return;
      }
      if (limitAmount > parseFloat(this.wallet.coin)) {
        this.$toast.error(this.$t("exchange.insufficientBalance"));
        this.loadingBidLimitButton = false;
        return;
      }
      let payload = {
        symbol: this.currentCoin.symbol,
        price: limitPrice,
        amount: limitAmount,
        direction: "SELL",
        type: "LIMIT_PRICE",
        useDiscount: this.isUseBHB ? "1" : "0", //whether to deduct the trial fee 0 not used 1 used
      };
      this.addOrder(payload)
          .then(() => {
            this.$toast.success(this.$t("exchange.success"));
            this.$emit("refresh");
            this.sell.limitAmount = "";
            this.sell.sliderPercent = 0;
          })
          .catch((err) => {
            this.$toast.error(err.message);
          })
          .finally(() => {
            this.loadingBidLimitButton = false;
          });
    },
    buyWithLimitPrice() {
      this.loadingAskLimitButton = true;
      const limitPrice = removeThousand(this.buy.limitPrice);
      const limitAmount = removeThousand(this.buy.limitAmount);

      if (!limitPrice) {
        this.$toast.error(this.$t("exchange.enterBuyPrice"));
        this.loadingAskLimitButton = false;
        return;
      }
      if (!limitAmount) {
        this.$toast.error(this.$t("exchange.enterBuyAmount"));
        this.loadingAskLimitButton = false;
        return;
      }
      const maxAmount = this.wallet.base / limitPrice;
      if (limitAmount > maxAmount) {
        this.$toast.error(this.$t("exchange.insufficientBalance"));
        this.loadingAskLimitButton = false;
        return;
      }
      let payload = {
        symbol: this.currentCoin.symbol,
        price: limitPrice,
        amount: limitAmount,
        direction: "BUY",
        type: "LIMIT_PRICE",
        useDiscount: this.isUseBHB ? "1" : "0", //whether to deduct the trial fee 0 not used 1 used
      };
      this.addOrder(payload)
          .then(() => {
            this.$toast.success(this.$t("exchange.success"));
            this.$emit("refresh");
            this.buy.limitAmount = "";
            this.buy.sliderPercent = 0;
          })
          .catch((err) => {
            this.$toast.error(err.message);
          })
          .finally(() => {
            this.loadingAskLimitButton = false;
          });
    },
  },
};
</script>
