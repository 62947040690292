<template>
  <Row type="flex" :gutter="30">
    <Col
      v-if="orderType === 'both' || orderType === 'buy'"
      :xs="{ span: 24 }"
      :lg="{ span: 12 }"
    >
      <Form ref="buyForm" :model="buy">
        <FormItem prop="stopActivationPrice">
          <label class="before" :style="inputLabelStyle">{{ $t("exchange.stopPrice") }}</label>
          <Input
            @on-keyup="handleActivationPrice('buy')"
            v-model="buy.stopActivationPrice"
            placeholder="0"
          >
            <span slot="prepend">
              {{ currentCoin.base }}
            </span>
          </Input>
          <!--          <label class="after">{{ currentCoin.base }}</label>-->
        </FormItem>
        <FormItem prop="stopOrderPrice">
          <label class="before" :style="inputLabelStyle">{{ $t("exchange.buyPrice") }}</label>
          <Input
            @on-keyup="handleOrderPrice('buy')"
            v-model="buy.stopOrderPrice"
            placeholder="0"
          >
            <span slot="prepend">
              {{ currentCoin.base }}
            </span>
          </Input>
          <!--          <label class="after">{{ currentCoin.base }}</label>-->
        </FormItem>
        <FormItem prop="stopAmount">
          <label class="before" :style="inputLabelStyle">{{ $t("exchange.buyAmount") }}</label>
          <Input
            @on-keyup="handleStopAmount('buy')"
            v-model="buy.stopAmount"
            placeholder="0"
          >
            <span slot="prepend">
              {{ currentCoin.coin }}
            </span>
          </Input>
          <!--          <label class="after">{{ currentCoin.coin }}</label>-->
        </FormItem>
        <FormItem class="slider-wrap" prop="sliderPercent">
          <Slider
            class="slider-buy"
            v-model="buy.sliderPercent"
            :step="1"
            show-tip="always"
            :tip-format="tipFormat"
            :disabled="buySliderDisabled"
            @on-change="handleBuySlider"
          ></Slider>
          <div
            class="slider-stop"
            :class="{ 'slider-stop-enable': !buySliderDisabled }"
            v-for="item in sliderStep"
            :key="`${item}-buy`"
            :style="'left: ' + item + '%;'"
            @click="updateStepper('buy', item, buySliderDisabled)"
          >
            <div
              class="slider-block"
              :class="{ 'buy-slider-block-active': buy.sliderPercent > item }"
            ></div>
          </div>
        </FormItem>
<!--        <div class="text-start font-Regular-12 text-grey mb-3">-->
<!--          {{ $t("exchange.amount") }}-->
<!--          <span>{{-->
<!--            buy.stopTurnover | numberWithCommas(toFloor(baseCoinScale))-->
<!--          }}</span>-->
<!--          {{ currentCoin.base }}-->
<!--        </div>-->
        <div class="d-flex justify-between align-center font-Regular-12 text-grey mb-8">
          {{ $t("exchange.estimatedReceipt") }}
          <p class="ltr">
            <img class="mr-2" src="@/assets/images/exchange/nearAmount.svg" />
            <span style="color: #FFFFFF">{{
                (removeThousand(buy.stopAmount) - (removeThousand(buy.stopAmount) * takerFee)) | numberWithCommas(toFloor(coinScale))
              }}
            </span>
            <span style="color: #FFFFFF">{{ currentCoin.coin }}</span>
          </p>
        </div>
        <Button
          :loading="loadingPreconditionAskButton"
          :disabled="!enableStopLimit || !isExchangeable || noBaseCoins"
          class="pt-2 pb-2 font-Regular-12 text-white fill-width"
          :class="
            enableStopLimit && isExchangeable && !noBaseCoins
              ? 'bg-green'
              : 'bg-gray'
          "
          @click="buyWithPreconditionPrice"
          >{{ $t("exchange.buy") }} ({{ currentCoin.coin }})
        </Button>
        <!--        <Button v-else class="bg-gray pt-2 pb-2 font-Bold-13 text-white fill-width">-->
        <!--          {{ $t("exchange.buyin") }} ({{ currentCoin.coin }})-->
        <!--        </Button>-->
      </Form>
    </Col>

    <Col
      v-if="orderType === 'both' || orderType === 'sell'"
      :xs="{ span: 24 }"
      :lg="{ span: 12 }"
    >
      <Form ref="sellForm" :model="sell">
        <FormItem prop="stopActivationPrice">
          <label class="before" :style="inputLabelStyle">{{ $t("exchange.stopPrice") }}</label>
          <Input
            @on-keyup="handleActivationPrice('sell')"
            v-model="sell.stopActivationPrice"
            placeholder="0"
          >
            <span slot="prepend">
              {{ currentCoin.base }}
            </span>
          </Input>
          <!--          <label class="after">{{ currentCoin.base }}</label>-->
        </FormItem>
        <FormItem prop="stopOrderPrice">
          <label class="before" :style="inputLabelStyle">{{ $t("exchange.sellPrice") }}</label>
          <Input
            @on-keyup="handleOrderPrice('sell')"
            v-model="sell.stopOrderPrice"
            placeholder="0"
          >
            <span slot="prepend">
              {{ currentCoin.base }}
            </span>
          </Input>
          <!--          <label class="after">{{ currentCoin.base }}</label>-->
        </FormItem>
        <FormItem prop="stopAmount">
          <label class="before" :style="inputLabelStyle">{{ $t("exchange.sellAmount") }}</label>
          <Input
            @on-keyup="handleStopAmount('sell')"
            v-model="sell.stopAmount"
            placeholder="0"
          >
            <span slot="prepend">
              {{ currentCoin.coin }}
            </span>
          </Input>
          <!--          <label class="after">{{ currentCoin.coin }}</label>-->
        </FormItem>
        <FormItem class="slider-wrap" prop="sliderPercent">
          <Slider
            class="silder-sell"
            v-model="sell.sliderPercent"
            :step="1"
            show-tip="always"
            :tip-format="tipFormat"
            :disabled="sellSliderDisabled"
            @on-change="handleSellSlider"
          ></Slider>
          <div
            class="slider-stop"
            :class="{ 'slider-stop-enable': !sellSliderDisabled }"
            v-for="item in sliderStep"
            :key="`${item}-sell`"
            :style="'left: ' + item + '%;'"
            @click="updateStepper('sell', item, sellSliderDisabled)"
          >
            <div
              class="slider-block"
              :class="{ 'sell-slider-block-active': sell.sliderPercent > item }"
            ></div>
          </div>
        </FormItem>
<!--        <div class="text-start font-Regular-12 text-grey mb-3">-->
<!--          {{ $t("exchange.amount") }}-->
<!--          <span>{{-->
<!--            sell.stopTurnover | numberWithCommas(toFloor(coinScale))-->
<!--          }}</span>-->
<!--          {{ currentCoin.base }}-->
<!--        </div>-->
        <div class="d-flex justify-between align-center font-Regular-12 text-grey mb-8">
          {{ $t("exchange.estimatedReceipt") }}
          <p class="ltr">
            <img class="mr-2" src="@/assets/images/exchange/nearAmount.svg" />
            <span style="color: #FFFFFF">{{
                ((removeThousand(sell.stopAmount) - (removeThousand(sell.stopAmount) * takerFee)) * removeThousand(sell.stopOrderPrice)) | numberWithCommas(toFloor(baseCoinScale))
              }}
            </span>
            <span style="color: #FFFFFF">{{ currentCoin.base }}</span>

          </p>
        </div>
        <Button
          :loading="loadingPreconditionBidButton"
          :disabled="!enableStopLimit || !isExchangeable || noBaseCoins"
          class="pt-2 pb-2 font-Regular-12 text-white fill-width"
          :class="
            enableStopLimit && isExchangeable && !noBaseCoins
              ? 'bg-red'
              : 'bg-gray'
          "
          @click="sellWithPreconditionPrice"
          >{{ $t("exchange.sell") }} ({{ currentCoin.coin }})
        </Button>
        <!--        <Button v-else class="bg-gray pt-2 pb-2 font-Bold-13 text-white fill-width">-->
        <!--          {{ $t("exchange.sellout") }} ({{ currentCoin.coin }})-->
        <!--        </Button>-->
      </Form>
    </Col>
  </Row>
</template>

<script>
import { handleInput, removeThousand } from "@/utiles/numericValidations";
import { mapActions } from "vuex";

export default {
  name: "MarketOrderForm",
  props: {
    orderType: {
      type: String,
      required: true,
    },
    currentCoin: {
      type: Object,
      required: true,
    },
    takerFee: {
      type: Number,
      required: true,
    },
    baseCoinScale: {
      type: Number,
      required: true,
    },
    coinScale: {
      type: Number,
      required: true,
    },
    wallet: {
      type: Object,
      required: true,
    },
    platesPrice: {
      type: String,
    },
    isExchangeable: {
      type: Boolean,
      required: true,
    },
    enableStopLimit: {
      type: Boolean,
      required: true,
    },
    noBaseCoins: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      buy: {
        stopActivationPrice: "",
        stopOrderPrice: "",
        stopAmount: "",
        stopTurnover: 0.0,
        sliderPercent: 0,
      },
      sell: {
        stopActivationPrice: "",
        stopOrderPrice: "",
        stopAmount: "",
        stopTurnover: 0.0,
        sliderPercent: 0,
      },
      sliderStep: [25, 50, 75, 100],
      isUseBHB: false,
      loadingPreconditionAskButton: false,
      loadingPreconditionBidButton: false,
    };
  },
  watch: {
    platesPrice(val, oldVal) {
      if (val !== oldVal) {
        this.resetForms();
        this.setPrices();
      }
    },
  },
  computed: {
    sellSliderDisabled() {
      let account = this.wallet.coin;
      let min = this.toFloor(1 / Math.pow(10, this.coinScale));
      return account < min || !this.enableStopLimit || !this.isExchangeable;
    },
    buySliderDisabled() {
      let account = this.wallet.base;
      let min = this.toFloor(1 / Math.pow(10, this.baseCoinScale));
      return account < min || !this.enableStopLimit || !this.isExchangeable;
    },
    inputLabelStyle() {
      return {
        right: this.isLocaleLTR ? '10px' : 'unset',
        left: this.isLocaleLTR ? 'unset' : '10px'
      };
    },
  },
  mounted() {
    this.resetForms();
    this.setPrices();
  },
  methods: {
    removeThousand,
    ...mapActions({
      addPreconditionOrder: "exchange/ADD_PRECONDITION_ORDER_SERVICE",
    }),
    resetForms() {
      if (this.orderType === "both") {
        this.$refs.buyForm.resetFields();
        this.$refs.sellForm.resetFields();
      } else {
        this.$refs[`${this.orderType}Form`].resetFields();
      }
    },
    setPrices() {
      this.buy.stopOrderPrice = handleInput(
        this.platesPrice,
        this.baseCoinScale
      );
      this.sell.stopOrderPrice = handleInput(
        this.platesPrice,
        this.baseCoinScale
      );
    },
    updateStepper(key, val, disabled) {
      if (!disabled) {
        this[key].sliderPercent = val;
        if (key === "buy") this.handleBuySlider(val);
        else this.handleSellSlider(val);
      }
    },
    tipFormat(val) {
      return val + "%";
    },
    handleOrderPrice(key) {
      this[key].stopOrderPrice = handleInput(
        this[key].stopOrderPrice,
        this.baseCoinScale
      );
      const stopOrderPrice = removeThousand(this[key].stopOrderPrice);
      const stopAmount = removeThousand(this[key].stopAmount);

      this[key].stopTurnover = this.toFloor(stopOrderPrice.mul(stopAmount));
      this.setSliderPercent(key, stopOrderPrice, stopAmount);
    },
    handleActivationPrice(key) {
      this[key].stopActivationPrice = handleInput(
        this[key].stopActivationPrice,
        this.baseCoinScale
      );
    },
    handleStopAmount(key) {
      this[key].stopAmount = handleInput(this[key].stopAmount, this.coinScale);
      const stopOrderPrice = removeThousand(this[key].stopOrderPrice);
      const stopAmount = removeThousand(this[key].stopAmount);

      this[key].stopTurnover = this.toFloor(stopAmount.mul(stopOrderPrice));
      this.setSliderPercent(key, stopOrderPrice, stopAmount);
    },
    setSliderPercent(key, stopOrderPrice, stopAmount) {
      let account = this.wallet.coin;
      let turnOver = stopAmount;
      if (key === "buy") {
        account = this.wallet.base;
        turnOver = stopOrderPrice.mul(stopAmount);
      }
      this[key].sliderPercent =
        account > 0 &&
        parseFloat(stopOrderPrice) > 0 &&
        parseFloat(stopAmount) > 0
          ? parseFloat(turnOver.div(account).mul(100))
          : 0;
    },
    handleBuySlider(val) {
      let price = removeThousand(this.buy.stopOrderPrice),
        account = this.wallet.base,
        amount = 0;
      if (price > 0) {
        amount = account.div(price).mul(val).mul(0.01);
      }
      this.buy.stopAmount = handleInput(amount, this.coinScale);
      this.buy.stopTurnover = this.toFloor(
        removeThousand(this.buy.stopAmount).mul(
          removeThousand(this.buy.stopOrderPrice)
        )
      );
    },
    handleSellSlider(val) {
      this.sell.stopAmount = handleInput(
        this.wallet.coin.mul(val).mul(0.01),
        this.coinScale
      );
      this.sell.stopTurnover = this.toFloor(
        removeThousand(this.sell.stopAmount).mul(
          removeThousand(this.sell.stopOrderPrice)
        )
      );
    },
    buyWithPreconditionPrice() {
      this.loadingPreconditionAskButton = true;
      const stopActivationPrice = removeThousand(this.buy.stopActivationPrice);
      const stopOrderPrice = removeThousand(this.buy.stopOrderPrice);
      const stopAmount = removeThousand(this.buy.stopAmount);

      if (!stopActivationPrice) {
        this.$toast.error(this.$t("exchange.enterActivationPrice"));
        this.loadingPreconditionAskButton = false;
        return;
      }
      if (!stopOrderPrice) {
        this.$toast.error(this.$t("exchange.enterBuyPrice"));
        this.loadingPreconditionAskButton = false;
        return;
      }
      if (!stopAmount) {
        this.$toast.error(this.$t("exchange.enterBuyAmount"));
        this.loadingPreconditionAskButton = false;
        return;
      }
      let payload = {
        symbol: this.currentCoin.symbol,
        orderPrice: stopOrderPrice,
        activationPrice: stopActivationPrice,
        amount: stopAmount,
        direction: "BUY",
        orderType: "LIMIT_PRICE",
      };
      this.addPreconditionOrder(payload)
        .then(() => {
          this.$toast.success(this.$t("exchange.success"));
          this.$emit("refresh");
          this.buy.stopAmount = "";
          this.buy.sliderPercent = 0;
        })
        .catch((err) => {
          this.$toast.error(err.message);
        })
        .finally(() => {
          this.loadingPreconditionAskButton = false;
        });
    },
    sellWithPreconditionPrice() {
      this.loadingPreconditionBidButton = true;
      const stopActivationPrice = removeThousand(this.sell.stopActivationPrice);
      const stopOrderPrice = removeThousand(this.sell.stopOrderPrice);
      const stopAmount = removeThousand(this.sell.stopAmount);

      if (!stopActivationPrice) {
        this.$toast.error(this.$t("exchange.enterActivationPrice"));
        this.loadingPreconditionBidButton = false;
        return;
      }
      if (!stopOrderPrice) {
        this.$toast.error(this.$t("exchange.enterSellPrice"));
        this.loadingPreconditionBidButton = false;
        return;
      }
      if (!stopAmount) {
        this.$toast.error(this.$t("exchange.enterSellAmount"));
        this.loadingPreconditionBidButton = false;
        return;
      }
      let payload = {
        symbol: this.currentCoin.symbol,
        orderPrice: stopOrderPrice,
        activationPrice: stopActivationPrice,
        amount: stopAmount,
        direction: "SELL",
        orderType: "LIMIT_PRICE",
      };
      this.addPreconditionOrder(payload)
        .then(() => {
          this.$toast.success(this.$t("exchange.success"));
          this.$emit("refresh");
          this.sell.stopAmount = "";
          this.sell.sliderPercent = 0;
        })
        .catch((err) => {
          this.$toast.error(err.message);
        })
        .finally(() => {
          this.loadingPreconditionBidButton = false;
        });
    },
  },
};
</script>

<style scoped></style>
