import expandRow from "@/components/Exchange/Expand/index";
import LocalTradingview from "@/components/Exchange/LocalTradingview";

var Stomp = require("stompjs");
var SockJS = require("sockjs-client");
import dayjs from "dayjs";
import DepthGraph from "@/components/Exchange/NewDepthGraph/index";
import MarketCountDown from "@/components/Exchange/MarketCountDown/index";
import Trading from "@/components/Exchange/Tradingview/index";
import PreconditionalOrderInfoModal from "@/components/Exchange/PreconditionalOrderInfoModal/index";
import OcoOrderInfoModal from "@/components/Exchange/OcoOrderInfoModal/index";
import ExchangeOrderTables from "@/components/Exchange/ExchangeOrderTables/index";
import MarketTable from "@/components/Exchange/MarketTable/index";
import {mapGetters, mapActions} from "vuex";
import BaseIcon from "@/components/UI/BaseIcon/index";

import LimitOrderForm from "@/components/Exchange/SubmitOrderForms/LimitOrderForm";
import MarketOrderForm from "@/components/Exchange/SubmitOrderForms/MarketOrderForm";
import PreconditionOrderForm from "@/components/Exchange/SubmitOrderForms/PreconditionOrderForm";
import OcoOrderForm from "@/components/Exchange/SubmitOrderForms/OcoOrderForm";

import Loader from "@/components/UI/LoadingComp"
import {numberWithCommas} from "@/utiles/filters";

export default {
    components: {
        expandRow,
        DepthGraph,
        MarketCountDown,
        Trading,
        ExchangeOrderTables,
        MarketTable,
        PreconditionalOrderInfoModal,
        OcoOrderInfoModal,
        BaseIcon,
        LocalTradingview,
        LimitOrderForm,
        MarketOrderForm,
        PreconditionOrderForm,
        OcoOrderForm,
        Loader
    },
    props: {
        pair: {
            type: String,
            default: "usdt_irr",
        },
    },
    data() {
        let self = this;
        return {
            coinTypeDescriptionModal: false,
            noBaseCoins: false,
            chartsLoading: false,
            currentOrdersLoading: true,
            historyOrdersLoading: true,
            preconditionsHistoryLoading: true,
            preconditionOrdersLoading: true,
            hasCoinScale: false,
            stomp: null,
            showPreconditionalOrderInfoModal: false,
            showOcoOrderInfoModal: false,
            baseUrl: process.env.VUE_APP_EXCOINO_PANEL_URL,
            orderType: "both",
            openOrdersData: [],
            ordersHistoryData: [],
            preconditionsHistoryData: [],
            stopOrdersData: [],
            historyTablePaginate: {
                pageSize: 10,
                total: 10,
                page: 1,
            },
            stopOrderTablePaginate: {
                pageSize: 10,
                total: 10,
                page: 1,
            },
            dataTableLoading: true,
            loadingBidMarketButton: false,
            loadingAskMarketButton: false,
            loadingBidLimitButton: false,
            loadingAskLimitButton: false,
            loadingPreconditionAskButton: false,
            loadingPreconditionBidButton: false,
            loadingOcoAskButton: false,
            loadingOcoBidButton: false,
            chartStatus: "MARKET",
            memberRate: 0,
            // userRealVerified: false, //real name authentication
            collecRequesting: false,
            currentCoinIsFavor: false,
            isUseBHB: false,
            skin: "night", //skin style day night
            selectedPlate: "all", //the currently displayed order
            orderBookLoading: true,
            tradesLoading: true,
            CNYRate: null,
            datafeed: null,
            defaultPath: "usdt_irr",
            // basecion: "usdt",
            indexBaseCoinBtn: [],
            baseCoinType: null,
            otherBaseCoins: [],
            selectedBaseCoin: "",
            chosenBaseCoinBtn: null,
            coinScale: 6,
            baseCoinScale: 6,
            makerFee: 0,
            takerFee: 0,
            symbolFee: 0.001,
            dataIndex: [],
            // dataIndex2: [],
            searchKey: "",
            coinInfo: {},
            currentCoin: {
                base: "",
                coin: "",
                symbol: "",
                price: 0,
                close: 0,
                rose: 0,
                high: 0,
                low: 0,
                twentyFourHourTurnover: 0
            },
            enableMarketBuy: 1, // 0: Disable 1: Enable
            enableMarketSell: 1,
            enableLimitBuy: 1, // 0: Disable 1: Enable
            enableLimitSell: 1,
            exchangeable: 1, // 0: tradable 1: not tradable
            enableStopLimit: true,
            enableOco: true,
            publishType: "NONE",
            currentTime: 0,
            publishAmount: 0,
            publishPrice: 0,
            startTime: "2000-01-01 00:00:00",
            endTime: "2000-01-01 00:00:00",
            clearTime: "2000-01-01 00:00:00",
            showPublish: false,
            showCountDown: false,
            countDownBgColor: "#003478",
            publishState: 0,
            platesPrice: "0",
            favorColumns: [],
            //Current trading currencies on the market, divided by trading pairs
            coins: {
                _map: [],
                // USDT: [],
                // USDT2: [],
                // DAI: [],
                // DAI2: [],
                // ETH: [],
                // ETH2: [],
                // BTCB: [],
                // BTCB2: [],
                favor: [],
                // BTCB: [],
                // ETH: [],
                // BNB: [],
                // BTCB2: [],
                // ETH2: [],
                // BNB2: [],
                columns: [],
            },
            wallet: {
                base: 0.0,
                coin: 0.0,
            },
            showMarket: "limitOrder",
            fixHistoryHeight: 295,
            // rechargeUrl:'#/finance/recharge',
            // rechargeUSDTUrl:'#/finance/recharge?name=USDT',
            trade: {
                rows: [],
                columns: [
                    {
                        title: self.$t("exchange.price"),
                        key: "price",
                        minWidth: 110,
                        align: "center",
                        render: (h, params) => {
                            const row = params.row;
                            const className = row.direction == "BUY" ? "buy" : "sell";

                            return h(
                                "span",
                                {
                                    attrs: {
                                        class: className,
                                    },
                                },
                                this.numberWithCommas(params.row.price, this.baseCoinScale)
                            );
                        },
                        renderHeader: (h) => {
                            const title =
                                self.$t("exchange.price") +
                                " " +
                                "(" +
                                self.currentCoin.base +
                                ")";
                            return h("span", {}, title);
                        },
                    },
                    {
                        title: self.$t("exchange.num"),
                        key: "amount",
                        minWidth: 120,
                        align: "center",
                        render: (h, params) => {
                            return h("span", {},
                                // params.row.amount.toFixed(this.coinScale)
                                this.numberWithCommas(params.row.amount, this.coinScale)
                            );
                        },
                        renderHeader: (h) => {
                            const title =
                                self.$t("exchange.num")
                            return h("span", {}, title);
                        },
                    },
                    {
                        title: self.$t("exchange.time"),
                        key: "time",
                        minWidth: 100,
                        align: "center",
                        render: (h, params) => {
                            return h(
                                "span",
                                {},
                                this.$options.filters.timeFormat(params.row.time)
                            );
                        },
                    },
                ],
            },
            //   the latest price table data;
            plate: {
                maxPostion: 10,
                columns: [
                    {
                        //   price;
                        title: self.$t("exchange.price"),
                        key: "price",
                        minWidth: 100,
                        align: "center",
                        render: (h, params) => {
                            let str = "";
                            let price = "";
                            const className = params.row.direction.toLowerCase();
                            params.row.price == 0 && (str = h("span", {}, "--"));
                            params.row.price != 0 &&
                            (price = this.numberWithCommas(params.row.price, this.baseCoinScale)) &&
                            (str = h(
                                "span",
                                {
                                    style: {
                                        color: className === 'sell' ? "#f15057" : "#00b275"
                                    },
                                    attrs: {
                                        class: "order-books-font",
                                    },
                                },
                                price
                            ));
                            return str;
                        },
                        renderHeader: (h) => {
                            const title =
                                self.$t("exchange.price") +
                                " " +
                                "(" +
                                self.currentCoin.base +
                                ")";
                            return h("span", {}, title);
                        },
                    },
                    {
                        title: self.$t("exchange.num"),
                        key: "amount",
                        minWidth: 105,
                        align: "center",
                        render: (h, params) => {
                            let str = "";
                            params.row.amount == 0 && (str = h("span", {}, "--"));
                            params.row.amount != 0 &&
                            (str = h(
                                "span",
                                {
                                    attrs: {
                                        class: "order-books-font",
                                    },
                                },
                                // params.row.amount.toFixed(this.coinScale)
                                this.numberWithCommas(params.row.amount, this.coinScale)
                            ));
                            return str;
                        },
                        renderHeader: (h) => {
                            const title =
                                self.$t("exchange.num")
                            return h("span", {}, title);
                        },
                    },
                    {
                        title: self.$t("exchange.total"),
                        key: "totalAmount",
                        minWidth: 110,
                        align: "center",
                        render: (h, params) => {
                            if (params.row.price == 0 || params.row.totalAmount == 0) {
                                return h("span", {}, "--");
                            } else {
                                return h(
                                    "span",
                                    {
                                        attrs: {
                                            class: "order-books-font",
                                        },
                                    },
                                    this.numberWithCommas(params.row.totalAmount, this.coinScale)
                                );
                            }
                        },
                        renderHeader: (h) => {
                            const title =
                                self.$t("exchange.total")
                            return h("span", {}, title);
                        },
                    },
                    {
                        className: "percenttd",
                        width: 1,
                        render: (h, params) => {
                            let width = "0",
                                backgroundColor =
                                    params.row.direction === "BUY" ? "#00b275" : "#f15057",
                                totle =
                                    params.row.direction === "BUY"
                                        ? this.plate.bidTotle
                                        : this.plate.askTotle;
                            if (params.row.totalAmount) {
                                width = (params.row.totalAmount / totle).toFixed(4) * 100 + "%";
                            }
                            return h(
                                "div",
                                {
                                    style: {
                                        width,
                                        backgroundColor,
                                    },
                                    attrs: {
                                        class: "percentdiv",
                                    },
                                },
                                " "
                            );
                        },
                    },
                ],
                askRows: [],
                bidRows: [],
            },
            fullTrade: {},
            defaultPair: "",
            coinVisibility: 1,
            checkDemoExchange: false
        };
    },
    mounted() {
        this.favorColumns = [
            {
                title: this.$t("exchange.coin"),
                key: "coin",
                sortable: false,
                align: this.isLocaleLTR ? "left" : "right",
                minWidth: 140,
                className: "coin-menu-symbol",
                render: (h, params) => {
                    // if (params.row.coin == "BZB") {
                    //   return h("div", [
                    //     h("Icon", {
                    //       props: {
                    //         // color:"red",
                    //         type: params.row.isFavor ? "ios-star" : "ios-star-outline",
                    //       },
                    //       nativeOn: {
                    //         click: () => {
                    //           event.stopPropagation(); //prevent events from bubbling up
                    //           if (this.isLogin) {
                    //             if (
                    //               event.currentTarget.className ==
                    //               "ivu-icon ivu-icon-ios-star"
                    //             ) {
                    //               this.cancelCollect(params.index, params.row);
                    //               event.currentTarget.className ==
                    //                 "ivu-icon ivu-icon-ios-star-outline";
                    //             } else {
                    //               this.collect(params.index, params.row);
                    //               event.currentTarget.className =
                    //                 "ivu-icon ivu-icon-ios-star";
                    //             }
                    //           } else {
                    //             this.$toast.warning(
                    //               "برای افزودن علاقمندی ها باید از طریق اکسکوینو وارد شوید"
                    //             );
                    //           }
                    //         },
                    //       },
                    //     }),
                    //     h("span", `${params.row.coin} / ${params.row.base}`),
                    //     h(
                    //       "span",
                    //       {
                    //         style: {
                    //           fontSize: "8px",
                    //           padding: "2px 5px 1px 5px",
                    //           color: "#FFF",
                    //           marginLeft: "5px",
                    //           background: "#F30",
                    //           borderRadius: "4px",
                    //         },
                    //       },
                    //       "HOT"
                    //     ),
                    //   ]);
                    // }
                    return h(
                        "div",
                        {
                            style: {
                                textAlign: "start",
                                // marginRight: "16px",
                            },
                        },
                        [
                            h("Icon", {
                                props: {
                                    // color:"red",
                                    type: params.row.isFavor ? "ios-star" : "ios-star-outline",
                                },
                                nativeOn: {
                                    click: () => {
                                        event.stopPropagation(); //prevent events from bubbling up
                                        if (this.isLogin) {
                                            if (
                                                event.currentTarget.className ==
                                                "ivu-icon ivu-icon-ios-star"
                                            ) {
                                                this.cancelCollect(params.index, params.row);
                                                event.currentTarget.className ==
                                                "ivu-icon ivu-icon-ios-star-outline";
                                            } else {
                                                this.collect(params.index, params.row);
                                                event.currentTarget.className =
                                                    "ivu-icon ivu-icon-ios-star";
                                            }
                                        } else {
                                            this.$toast.warning(
                                                this.$t('exchange.addFavorWarning')
                                            );
                                        }
                                    },
                                },
                            }),
                            h("span", this.showPair(params.row)),
                        ]
                    );
                },
            },
            {
                title: this.$t("exchange.lastPrice"),
                key: "close",
                minWidth: 115,
                align: this.isLocaleLTR ? "left" : "right",
                sortable: true,
                render: (h, params) => {
                    return h("span", {}, this.numberWithCommas(params.row.close));
                },
                sortMethod: function (a, b, type) {
                    let a1 = parseFloat(a);
                    let b1 = parseFloat(b);
                    if (type == "asc") {
                        return a1 - b1;
                    } else {
                        return b1 - a1;
                    }
                },
            },
            {
                title: this.$t("exchange.dayChange"),
                key: "rose",
                minWidth: 110,
                align: "center",
                sortable: true,
                sortMethod: function (a, b, type) {
                    let a1 = a.replace(/[^\d|.|-]/g, "") - 0;
                    let b1 = b.replace(/[^\d|.|-]/g, "") - 0;
                    if (type == "asc") {
                        return a1 - b1;
                    } else {
                        return b1 - a1;
                    }
                },
                render: (h, params) => {
                    const row = params.row;
                    const className =
                        parseFloat(row.rose) >= 0
                            ? parseFloat(row.rose) === 0
                                ? ""
                                : "buy"
                            : "sell";
                    return h(
                        "span",
                        {
                            attrs: {
                                class: className,
                            },
                        },
                        row.rose
                    );
                },
            },
        ]
        this.coins.columns = [
            {
                title: this.$t("exchange.coin"),
                key: "coin",
                sortable: false,
                minWidth: 125,
                align: this.isLocaleLTR ? "left" : "right",
                className: "coin-menu-symbol",
                render: (h, params) => {
                    return h(
                        "div",
                        {
                            style: {
                                textAlign: "start"
                            },
                        },
                        [
                            h("Icon", {
                                props: {
                                    // color:"red",
                                    type: params.row.isFavor
                                        ? "ios-star"
                                        : "ios-star-outline",
                                },
                                nativeOn: {
                                    click: () => {
                                        event.stopPropagation(); //prevent events from bubbling up
                                        if (this.isLogin) {
                                            if (
                                                event.currentTarget.className ==
                                                "ivu-icon ivu-icon-ios-star"
                                            ) {
                                                this.cancelCollect(params.index, params.row);
                                                event.currentTarget.className ==
                                                "ivu-icon ivu-icon-ios-star-outline";
                                            } else {
                                                this.collect(params.index, params.row);
                                                event.currentTarget.className =
                                                    "ivu-icon ivu-icon-ios-star";
                                            }
                                        } else {
                                            this.$toast.warning(
                                                this.$t('exchange.addFavorWarning')
                                            );
                                        }
                                    },
                                },
                            }),
                            h("span", params.row.coin),
                        ]
                    );
                },
            },
            {
                title: this.$t("exchange.lastPrice"),
                key: "close",
                minWidth: 115,
                align: this.isLocaleLTR ? "left" : "right",
                sortable: true,
                render: (h, params) => {
                    return h("span", {}, this.numberWithCommas(params.row.close, this.getPairCoinScales[params.row.symbol].frontBaseCoinScale));
                },
                sortMethod: function (a, b, type) {
                    let a1 = parseFloat(a);
                    let b1 = parseFloat(b);
                    if (type == "asc") {
                        return a1 - b1;
                    } else {
                        return b1 - a1;
                    }
                },
            },
            {
                title: this.$t("exchange.dayChange"),
                key: "rose",
                align: "center",
                minWidth: 110,
                sortable: true,
                sortMethod: function (a, b, type) {
                    let a1 = a.replace(/[^\d|.|-]/g, "") - 0;
                    let b1 = b.replace(/[^\d|.|-]/g, "") - 0;
                    if (type == "asc") {
                        return a1 - b1;
                    } else {
                        return b1 - a1;
                    }
                },
                render: (h, params) => {
                    const row = params.row;
                    const className =
                        parseFloat(row.rose) >= 0
                            ? parseFloat(row.rose) === 0
                                ? ""
                                : "buy"
                            : "sell";
                    return h(
                        "div",
                        {
                            style: {
                                direction: "ltr",
                            },
                            attrs: {
                                class: className,
                            },
                        },
                        row.rose
                    );
                },
            },
        ]
    },
    filters: {
        toFixedPublishAmount: function (value) {
            var tem = value.toFixed(7);
            if (value >= 10) {
                tem = value.toFixed(6);
            }
            if (value >= 100) {
                tem = value.toFixed(5);
            }
            if (value >= 1000) {
                tem = value.toFixed(4);
            }
            if (value >= 10000) {
                tem = value.toFixed(3);
            }
            if (value >= 100000) {
                tem = value.toFixed(2);
            }
            if (value >= 1000000) {
                tem = value.toFixed(1);
            }
            if (value >= 10000000) {
                tem = value.toFixed(0);
            }
            return tem;
        },
        toFixedPublishPrice: function (value) {
            var tem = value.toFixed(5).replace(/0+$/g, "");
            if (value >= 1) {
                tem = value.toFixed(4);
            }
            if (value >= 10) {
                tem = value.toFixed(3);
            }
            if (value >= 100) {
                tem = value.toFixed(2);
            }
            if (value >= 1000) {
                tem = value.toFixed(1);
            }
            if (value >= 10000) {
                tem = value.toFixed(1);
            }
            return tem;
        },
    },
    computed: {
        ...mapGetters({
            getMember: "global/MEMBER",
            getLoginStatus: "global/IS_LOGIN",
            getTradingStatus: "global/GET_TRADING_STATUS",
            getLang: "global/GET_LANG",
            getPairCoinScales: "market/GET_PAIR_COIN_SCALES",
        }),
        orderFormName() {
            let formName;
            switch (this.showMarket) {
                case "limitOrder":
                    formName = "LimitOrderForm";
                    break;
                case "marketOrder":
                    formName = "MarketOrderForm";
                    break;
                case "stopLimitOrder":
                    formName = "PreconditionOrderForm";
                    break;
                case "ocoOrder":
                    formName = "OcoOrderForm";
                    break;
            }
            return formName;
        },
        pairParam() {
            return this.$route.params.pair ? this.$route.params.pair : this.pair;
        },
        isExchangeable() {
            return this.exchangeable === 1 || this.isAdmin;
        },
        checkActiveBaseCoin() {
            return this.otherBaseCoins.find((elem) => elem.unit === this.selectedBaseCoin);
        },
        rerenderApp() {
            let elem = document.getElementById("tradingViewID");
            if (elem) {
                document.getElementById("tradingViewID").parentNode.removeChild(elem);
            }
            return this.pairParam;
        },
        isAdmin() {
            return this.getMember ? this.getMember.isAdmin : false;
        },
        // rechargeUSDTUrl() {
        //   return "/deposit?name=" + this.currentCoin.base;
        //   // return "#/finance/deposit?name=" + this.currentCoin.base;
        // },
        // rechargeCoinUrl() {
        //   return "/deposit?name=" + this.currentCoin.coin;
        //   // return "#/finance/deposit?name=" + this.currentCoin.coin;
        // },
        isLogin() {
            return this.getLoginStatus;
        },
        tradingStatus() {
            return this.getTradingStatus;
        },
        member() {
            return this.getMember;
        },
        lang() {
            return this.getLang;
        },
    },
    watch: {
        lang: function () {
            this.updateLangData();
        },
        $route(to, from) {
            if (from.params.pair) {
                this.init();
            }
        },
        currentCoin: {
            handler: function () {
                document.title = `${numberWithCommas(this.currentCoin.close, this.baseCoinScale)} | ${this.currentCoin.symbol}`
            }, deep: true
        }
    },
    created() {
        this.$router.replace(
            {name: "ExchangePair", params: {pair: this.pairParam}},
            () => {
            }
        );
        this.init();
        this.changeOrderType("sell");
        window.addEventListener("resize", this.changeOrderTypeOnResize);
    },
    destroyed() {
        window.removeEventListener("resize", this.changeOrderTypeOnResize);
    },
    methods: {
        // test
        customLabel(item, index) {
            const h = this.$createElement;
            if (index === 0 && item.unit === 'fav') {
                return h('span', {
                    class: {
                        'active': index == this.chosenBaseCoinBtn,
                        'ivu-btn-default': index != this.chosenBaseCoinBtn
                    },
                    on: {
                        click: (event) => {
                            event.stopPropagation();
                            this.changeBaseCoin(item.unit, index);
                        }
                    }
                }, [
                    h('Icon', {
                        props: {
                            color: "#f0a70a",
                            size: 16,
                            type: "ios-star"
                        }
                    })
                ]);
            } else if (item.unit !== 'fav') {
                return h('span', {
                    class: {
                        'active': index == this.chosenBaseCoinBtn,
                        'ivu-btn-default': index != this.chosenBaseCoinBtn,
                        'demo-trading-text': item.coinType === 'VIRTUAL'
                    },
                    on: {
                        click: (event) => {
                            event.stopPropagation();
                            this.changeBaseCoin(item.unit, index);
                        }
                    }
                }, [
                    item.coinType === 'VIRTUAL' ? this.$t('exchange.demoMarket') : item.localedUnit
                ]);
            }
            return null;
        },
        // end
        disableChartsLoading() {
            const loader = document.getElementById("loader");
            if (loader) loader.classList.add("d-none");
            this.chartsLoading = false;
        },
        ...mapActions({
            setNavigation: "global/NAVIGATE",
            setExchangeSkin: "global/SET_SKIN",
            setDepositStatus: "global/SET_DEPOSIT_STATUS",
            setWithdrawStatus: "global/SET_WITHDRAW_STATUS",
            setTradingStatus: "global/SET_TRADING_STATUS",
            getUserWallet: "userCenter/WALLET_SERVICE",
            getBaseSymbolsList: "market/GET_BASE_SYMBOLS_SERVICE",
            getSymbolThumb: "market/GET_SYMBOL_THUMB_SERVICE",
            getCoinData: "market/GET_COIN_INFO_SERVICE",
            getSymbolInfo: "market/GET_SYMBOL_INFO_SERVICE",
            getPlateMini: "market/GET_EXCHANGE_PLATE_MINI_SERVICE",
            getPlateFullData: "market/GET_EXCHANGE_PLATE_FULL_SERVICE",
            getLatestTrades: "market/GET_LATEST_TRADE_SERVICE",
            getFavoredMarkets: "exchange/GET_ALL_FAVORED_MARKETS_SERVICE",
            deleteFavoredMarket: "exchange/DELETE_FAVORED_MARKET_SERVICE",
            addFavoredMarket: "exchange/ADD_FAVORED_MARKET_SERVICE",
            getOpenOrders: "exchange/GET_ORDERS_CURRENT_LIST_SERVICE",
            getOrdersHistory: "exchange/HISTORY_SERVICE",
            getAllPreconditionsHistory:
                "exchange/GET_ALL_PRECONDITIONS_HISTORY_SERVICE",
            getAllActivePreconditions:
                "exchange/GET_ALL_ACTIVE_PRECONDITIONS_SERVICE",
        }),
        showPair(row) {
            return this.isLogin ? `${row.coin}/${row.base}` : row.coin;
        },
        searchBaseCoins(value, option) {
            return option.toUpperCase().indexOf(value.toUpperCase()) !== -1;
        },
        openInfoModal(orderType) {
            if (orderType === "stopLimitOrder")
                this.showPreconditionalOrderInfoModal = true;
            else if (orderType === "ocoOrder") this.showOcoOrderInfoModal = true;
        },
        confirmInfo() {
            this.showPreconditionalOrderInfoModal = false;
            this.showOcoOrderInfoModal = false;
        },
        changeOrderTypeOnResize(type) {
            if (type.target.innerWidth <= 800) {
                if (this.orderType === "both") {
                    this.orderType = "sell";
                }
            } else {
                this.orderType = "both";
            }
        },
        changeOrderType(type) {
            if (window.innerWidth <= 800) {
                this.orderType = type;
            } else {
                this.orderType = "both";
            }
        },
        searchInputChange() {
            this.searchKey = this.searchKey.toUpperCase();
            if (this.selectedBaseCoin === "fav") {
                this.dataIndex = this.coins.favor.filter(
                    (item) => item["symbol"].toUpperCase().indexOf(this.searchKey) == 0
                );
            } else {
                this.dataIndex = this.coins[this.selectedBaseCoin].filter(
                    (item) => item["symbol"].toUpperCase().indexOf(this.searchKey) == 0
                );
            }
        },
        init() {
            const baseCoin = this.pairParam.split("_")[1];
            if (baseCoin) {
                this.selectedBaseCoin = baseCoin.toUpperCase();
            }
            var coin = this.pairParam.toUpperCase().split("_")[0];
            var base = this.pairParam.toUpperCase().split("_")[1];
            this.currentCoin.symbol = coin + "/" + base;
            this.currentCoin.coin = coin;
            this.currentCoin.base = base;
            this.setNavigation("nav-exchange");
            this.setExchangeSkin(this.skin);
            // this.getCNYRate();
            this.getBaseSymbols();

        },
        changeBaseCoin(baseCoin, index) {
            this.searchKey = "";
            this.chosenBaseCoinBtn = index;
            this.selectedBaseCoin = baseCoin;
            if (baseCoin === "fav") {
                this.dataIndex = this.coins.favor;
            } else {
                this.dataIndex = this.coins[baseCoin];
            }
        },
        changePlate(str) {
            if (str != "all") {
                this.plate.maxPostion = 20;
            } else {
                this.plate.maxPostion = 10;
            }
            this.getPlate(str);
            //this.selectedPlate = str;
        },
        setback() {
            var obk = document.getElementsByClassName("container")[0];
            var height = 0;
            var doc = document;
            window.innerHeight && (height = window.innerHeight);
            !window.innerHeight &&
            doc.body.clientHeight &&
            (height = doc.body.clientHeight);
            !window.innerHeight &&
            !doc.body.clientHeight &&
            doc.documentElement.clientHeight &&
            (height = doc.documentElement.clientHeight);
            obk.style.minHeight = height - 100 + "px";
        },
        getCoin(symbol) {
            return this.coins._map[symbol];
        },
        getFavor() {
            //Query optional (favorites)
            console.log("get favor started")
            this.getFavoredMarkets().then((response) => {
                this.coins.favor = [];
                this.currentCoinIsFavor = false;
                for (let i = 0; i < response.data.length; i++) {
                    let coin = this.getCoin(response.data[i].symbol);
                    if (coin != null) {
                        coin.isFavor = true;
                        this.coins.favor.push(coin);
                    }
                    if (this.currentCoin.symbol === response.data[i].symbol) {
                        this.currentCoinIsFavor = true;
                    }
                }
            });
        },
        getBaseSymbols() {
            this.hasCoinScale = false;
            this.getBaseSymbolsList().then(async (response) => {
                this.indexBaseCoinBtn = response.data.data.map(item => {
                    return item.unit;
                });
                this.baseCoinType = response.data.data
                this.checkDemoExchange = false
                if (this.isLogin) {
                    this.baseCoinType.unshift({
                        unit: "fav",
                        coinType: "ORDINARY"
                    });
                }
                let base = this.$route.params.pair.split("_")[1].toUpperCase()
                this.otherBaseCoins = [];
                this.baseCoinType.forEach((item, i) => {
                    if (i > 3) this.otherBaseCoins.push(item);
                    if (item.coinType === "VIRTUAL" && item.unit === base) {
                        this.checkDemoExchange = true
                    }
                })
                if (!this.indexBaseCoinBtn.length) {
                    this.noBaseCoins = true;
                    this.orderBookLoading = false;
                    this.tradesLoading = false;
                    this.chartsLoading = false;
                    this.dataTableLoading = false;
                    return;
                }
                // this.chosenBaseCoinBtn = 1;
                // this.otherBaseCoins = [];
                this.indexBaseCoinBtn.forEach((el, i) => {
                    this.coins[el] = [];
                });
                await this.tradeData();
                await this.getSymbolScale();
                await this.getSymbol();
            });
        },
        tradeData() {
            this.getCoinInfo();
            // this.getSymbol(); //contains candlestick chart、getFavor、startWebsockEtc
            this.getPlate(); //buy and sell order
            this.getPlateFull();
            this.getTrade();
            if (this.isLogin) {
                this.getWallet(); //account asset information
                this.getCurrentOrder(); //current commission
                this.getHistoryOrder(); //historical commission
                this.getPreconditionsHistory();
                // this.getAllOrderTypesHistoryList();
                this.getAllPreconditionOrders();
            }
        },
        async getSymbol() {
            this.chartsLoading = true;
            this.dataTableLoading = true;
            this.getSymbolThumb().then(async (response) => {
                this.defaultPair = response.data[0];
                //clear the existing data first
                for (let i = 0; i < response.data.length; i++) {
                    let coin = response.data[i];
                    coin.base = response.data[i].symbol.split("/")[1];
                    this.coins[coin.base] = [];
                    this.coins[coin.base + "2"] = [];
                    this.coins._map = [];
                    this.coins.favor = [];
                }
                for (let i = 0; i < response.data.length; i++) {
                    let coin = response.data[i];
                    coin.price = response.data[i].close = response.data[i].close.toFixed(
                        this.getPairCoinScales[coin.symbol].frontBaseCoinScale
                    );
                    coin.rose =
                        response.data[i].chg > 0
                            ? "+" + (response.data[i].chg * 100).toFixed(2) + "%"
                            : (response.data[i].chg * 100).toFixed(2) + "%";
                    coin.coin = response.data[i].symbol.split("/")[0];
                    coin.base = response.data[i].symbol.split("/")[1];
                    coin.href = (coin.coin + "_" + coin.base).toLowerCase();
                    coin.isFavor = false;
                    this.coins._map[coin.symbol] = coin;
                    if (coin.zone === 0) {
                        this.coins[coin.base].push(coin);
                    } else {
                        this.coins[coin.base + "2"].push(coin);
                    }
                    if (coin.symbol === this.currentCoin.symbol) {
                        this.currentCoin = coin;
                        this.platesPrice = coin.price;
                        // this.currentCoin.coinScale = this.coinScale;
                        // this.currentCoin.baseCoinScale = this.baseCoinScale;
                    }
                }
                this.chosenBaseCoinBtn = this.indexBaseCoinBtn.indexOf(
                    this.selectedBaseCoin
                );
                // (index + 1) Because it is favored at index 0, which is manually created when user is login
                this.isLogin ?
                    this.changeBaseCoin(this.selectedBaseCoin, this.chosenBaseCoinBtn + 1) :
                    this.changeBaseCoin(this.selectedBaseCoin, this.chosenBaseCoinBtn)

                if (this.coinVisibility === 0 && !this.isAdmin) {
                    this.$router.push(
                        {
                            name: "ExchangePair",
                            params: {
                                pair: `${this.defaultPair.coin.toLowerCase()}_${this.defaultPair.base.toLowerCase()}`,
                            },
                        },
                        () => {
                        }
                    );
                }

                if (this.isLogin) {
                    await this.getFavor();
                }
                // require(["../../assets/js/exchange.js"], function (e) {
                //     e.clickScTab();
                // });
                this.startWebsock();
            })
                .finally(() => {
                    this.dataTableLoading = false;
                })
        },
        getCoinInfo() {
            //get accuracy
            let payload = {
                unit: this.currentCoin.coin,
            };
            this.getCoinData(payload).then((response) => {
                if (response.data != null) {
                    this.coinInfo = response.data;
                }
            });
        },
        getSymbolScale() {
            //get accuracy
            let payload = {
                symbol: `${this.$route.params.pair.split("_")[0].toUpperCase() + "/" + this.$route.params.pair.split("_")[1].toUpperCase()}`
                // symbol: this.currentCoin.symbol,
            };
            this.getSymbolInfo(payload).then((response) => {
                if (response.data != null) {
                    if (response.data.visible === 2) {
                        this.coinVisibility = 0
                    } else {
                        this.coinVisibility = 1
                    }
                    // this.currentCoin.coinScale = response.data.frontCoinScale;
                    // this.currentCoin.baseCoinScale = response.data.frontBaseCoinScale;

                    this.baseCoinScale = response.data.frontBaseCoinScale;
                    this.coinScale = response.data.frontCoinScale;
                    this.symbolFee = response.data.fee;

                    this.makerFee = response.data.makerFee
                    this.takerFee = response.data.takerFee

                    this.enableMarketBuy = response.data.enableMarketBuy;
                    this.enableMarketSell = response.data.enableMarketSell;

                    this.enableLimitBuy = response.data.enableLimitBuy;
                    this.enableLimitSell = response.data.enableLimitSell;

                    this.exchangeable = response.data.exchangeable;

                    this.enableStopLimit = response.data.enableStopLimit;
                    this.enableOco = response.data.enableOCO;

                    this.publishType = response.data.publishType;
                    this.startTime = response.data.startTime;
                    this.endTime = response.data.endTime;
                    this.clearTime = response.data.clearTime;
                    this.currentTime = parseInt(response.data.currentTime / 1000);
                    this.publishAmount = response.data.publishAmount;
                    this.publishPrice = response.data.publishPrice;
                    let temCurT = dayjs(response.data.currentTime)
                        .format("YYYY-MM-DD HH:mm:ss");
                    // let temCurT
                    // if (this.$i18n.locale === 'fa') {
                    //     temCurT = dayjs(response.data.currentTime)
                    //         .calendar("gregory")
                    //         .format("YYYY-MM-DD HH:mm:ss");
                    // } else {
                    //     temCurT = dayjs(response.data.currentTime)
                    //         .format("YYYY-MM-DD HH:mm:ss");
                    // }
                    if (temCurT < this.clearTime) {
                        if (
                            this.publishType === "QIANGGOU" ||
                            this.publishType === "FENTAN"
                        ) {
                            // this.showPublish = true;
                            this.showCountDown = true;
                        } else {
                            this.showPublish = false;
                            this.showCountDown = false;
                        }
                    } else {
                        this.showPublish = false;
                        this.showCountDown = false;
                    }

                    this.hasCoinScale = true;

                    // get currency information
                }

            });
        },
        getPlate(str = "") {
            this.plate.askRows = [];
            this.plate.bidRows = [];
            this.orderBookLoading = true;
            //buy and sell order
            let payload = {
                symbol: this.currentCoin.symbol,
            };
            this.getPlateMini(payload).then((response) => {
                let askData = response.data.ask;
                let bidData = response.data.bid;
                if (askData && askData.items) {
                    for (let i = 0; i < askData.items.length; i++) {
                        if (i == 0) {
                            askData.items[i].totalAmount = askData.items[i].amount;
                        } else {
                            askData.items[i].totalAmount =
                                askData.items[i - 1].totalAmount + askData.items[i].amount;
                        }
                    }
                    if (askData.items.length >= this.plate.maxPostion) {
                        for (let i = this.plate.maxPostion; i > 0; i--) {
                            let ask = askData.items[i - 1];
                            ask.direction = "SELL";
                            ask.position = i;
                            this.plate.askRows.push(ask);
                        }
                        const rows = this.plate.askRows,
                            // len = rows.length,
                            totle = rows[0].totalAmount;
                        this.plate.askTotle = totle;
                    } else {
                        for (let i = this.plate.maxPostion; i > askData.items.length; i--) {
                            let ask = {price: 0, amount: 0};
                            ask.direction = "SELL";
                            ask.position = i;
                            ask.totalAmount = ask.amount;
                            this.plate.askRows.push(ask);
                        }
                        for (let i = askData.items.length; i > 0; i--) {
                            let ask = askData.items[i - 1];
                            ask.direction = "SELL";
                            ask.position = i;
                            this.plate.askRows.push(ask);
                        }
                        let askItemIndex =
                            askData.items.length - 1 >= 0
                                ? this.plate.maxPostion - askData.items.length
                                : 0;
                        const rows = this.plate.askRows,
                            // len = rows.length,
                            totle = rows[askItemIndex].totalAmount;
                        this.plate.askTotle = totle;
                    }
                }
                if (bidData && bidData.items) {
                    for (let i = 0; i < bidData.items.length; i++) {
                        if (i == 0) bidData.items[i].totalAmount = bidData.items[i].amount;
                        else
                            bidData.items[i].totalAmount =
                                bidData.items[i - 1].totalAmount + bidData.items[i].amount;
                    }
                    for (let i = 0; i < bidData.items.length; i++) {
                        let bid = bidData.items[i];
                        bid.direction = "BUY";
                        bid.position = i + 1;
                        this.plate.bidRows.push(bid);
                        if (i == this.plate.maxPostion - 1) break;
                    }
                    if (bidData.items.length < this.plate.maxPostion) {
                        for (let i = bidData.items.length; i < this.plate.maxPostion; i++) {
                            let bid = {price: 0, amount: 0};
                            bid.direction = "BUY";
                            bid.position = i + 1;
                            bid.totalAmount = 0;
                            this.plate.bidRows.push(bid);
                        }
                        let bidItemIndex =
                            bidData.items.length - 1 > 0 ? bidData.items.length - 1 : 0;
                        const rows = this.plate.bidRows,
                            // len = rows.length,
                            totle = rows[bidItemIndex].totalAmount;
                        this.plate.bidTotle = totle;
                    } else {
                        const rows = this.plate.bidRows,
                            len = rows.length,
                            totle = rows[len - 1].totalAmount;
                        this.plate.bidTotle = totle;
                    }
                }
                if (str != "") {
                    this.selectedPlate = str;
                }
            })
                .finally(() => {
                    this.orderBookLoading = false;
                })
        },
        getPlateFull() {
            //depth map
            let payload = {
                symbol: this.currentCoin.symbol,
            };
            return this.getPlateFullData(payload).then((response) => {
                this.fullTrade = response.data;
                // resp.skin = this.skin;
                // this.$refs.depthGraph.draw(resp);
            });
        },
        updatePlate(type, row) {
            //found that the method is not used
            if (type == "sell") {
                for (let i = 0; i < this.plate.askRows.length; i++) {
                    if (
                        row.price > this.plate.askRows[i].price &&
                        i != 0 &&
                        this.plate.askRows[i].price > 0
                    ) {
                        this.plate.askRows.splice(i, 0, row);
                        this.plate.askRows.shift();
                        break;
                    } else if (
                        i == this.plate.askRows.length - 1 &&
                        (row.price < this.plate.askRows[i].price ||
                            this.plate.askRows[i].price == 0)
                    ) {
                        this.plate.askRows.push(row);
                        this.plate.askRows.shift();
                        break;
                    }
                }
            } else if (type == "buy") {
                for (let i = 0; i < this.plate.bidRows.length; i++) {
                    if (row.price > this.plate.bidRows[i].price) {
                        this.plate.bidRows.splice(i, 0, row);
                        this.plate.bidRows.pop();
                        break;
                    }
                }
            }
        },
        getTrade() {
            this.trade.rows = [];
            this.tradesLoading = true;
            let payload = {
                symbol: this.currentCoin.symbol,
                size: 20,
            };
            this.getLatestTrades(payload).then((response) => {
                for (let i = 0; i < response.data.length; i++) {
                    this.trade.rows.push(response.data[i]);
                }
            })
                .finally(() => {
                    this.tradesLoading = false;
                })
        },
        startWebsock() {
            if (this.stompClient) {
                this.stompClient.ws.close();
            }
            var stompClient = null;
            var that = this;
            // let scale = that.baseCoinScale;
            var socket = new SockJS(that.host + that.api.market.ws);
            stompClient = Stomp.over(socket);
            this.stompClient = stompClient;
            stompClient.debug = false;
            // this.datafeed = new Datafeeds.WebsockFeed(that.host+'/market',this.currentCoin,stompClient);
            // this.getKline();
            stompClient.connect({}, () => {
                this.stomp = stompClient;
                // that.datafeed = new Datafeeds.WebsockFeed(
                //     that.host + "/market",
                //     that.currentCoin,
                //     stompClient,
                //     that.baseCoinScale
                // );
                // if(this.tradingStatus !== "GLOBAL") {
                //     that.getKline();
                // }
                // stompClient.subscribe('/topic/market/kline/'+'BTCB/USDT'+'/'+'1min', function(msg) {
                //     // if (resolution != "1") return;
                //     // if (that.currentBar != null) onRealtimeCallback(that.currentBar);
                //     var resp = JSON.parse(msg.body);
                //     // that.lastBar = {time:resp.time,open:resp.openPrice,high:resp.highestPrice,low:resp.lowestPrice,close:resp.closePrice,volume:resp.volume};
                //     // that.currentBar = that.lastBar;
                //     // onRealtimeCallback(that.lastBar);
                //     // that.lastBar.newKlineStatus = true;
                // });
                //subscribe to price change news
                stompClient.subscribe("/topic/market/thumb", function (msg) {
                    var resp = JSON.parse(msg.body);
                    var coin = that.getCoin(resp.symbol);
                    if (coin != null) {
                        // coin.price = resp.close.toFixed(2);
                        coin.price = resp.close;
                        coin.rose =
                            resp.chg > 0
                                ? "+" + (resp.chg * 100).toFixed(2) + "%"
                                : (resp.chg * 100).toFixed(2) + "%";
                        // coin.close = resp.close.toFixed(2);
                        // coin.high = resp.high.toFixed(2);
                        // coin.low = resp.low.toFixed(2);
                        // coin.close = parseFloat(resp.close).toFixed(scale);
                        coin.close = parseFloat(resp.close);
                        coin.high = resp.high;
                        coin.low = resp.low;
                        coin.turnover = parseInt(resp.volume);
                        coin.volume = resp.volume;
                        coin.twentyFourHourTurnover = resp.twentyFourHourTurnover;
                        coin.twentyFourHourVolume = resp.twentyFourHourVolume;
                        coin.usdRate = resp.usdRate;
                        coin.change = resp.change;
                    }

                    if (that.coinVisibility === 0 && that.isAdmin && resp.symbol.split("/")[0] === that.$route.params.pair.split("_")[0].toUpperCase()
                        && resp.symbol.split("/")[1] === that.$route.params.pair.split("_")[1].toUpperCase()
                    ) {
                        that.currentCoin = {
                            base: that.$route.params.pair.split("_")[1].toUpperCase(),
                            baseUsdRate: 1,
                            chg: 0,
                            coin: that.$route.params.pair.split("_")[0].toUpperCase(),
                            href: that.$route.params.pair,
                            isFavor: false,
                            lastDayClose: 0,
                            oneHourTurnover: 0,
                            oneHourVolume: 0,
                            open: 0,
                            symbol: `${that.$route.params.pair.split("_")[0].toUpperCase() + "/" + that.$route.params.pair.split("_")[1].toUpperCase()}`,
                            zone: 0,
                            price: resp.close,
                            rose:
                                resp.chg > 0
                                    ? "+" + (resp.chg * 100).toFixed(2) + "%"
                                    : (resp.chg * 100).toFixed(2) + "%",
                            close: parseFloat(resp.close),
                            high: resp.high,
                            low: resp.low,
                            turnover: parseInt(resp.volume),
                            volume: resp.volume,
                            twentyFourHourTurnover: resp.twentyFourHourTurnover,
                            twentyFourHourVolume: resp.twentyFourHourVolume,
                            usdRate: resp.usdRate,
                            change: resp.change,
                        }
                    }
                });
                //subscribe to real time transaction information
                stompClient.subscribe(
                    "/topic/market/trade/" + that.currentCoin.symbol,
                    function (msg) {
                        var resp = JSON.parse(msg.body);
                        if (resp.length > 0) {
                            for (var i = 0; i < resp.length; i++) {
                                that.trade.rows.unshift(resp[i]);
                            }
                        }
                        if (that.trade.rows.length > 30) {
                            that.trade.rows = that.trade.rows.slice(0, 30);
                        }
                        // Made this change due to an issue with price
                        // that.currentCoin.price = resp[resp.length - 1].price;
                    }
                );
                if (that.isLogin) {
                    //subscription commission cancellation information
                    stompClient.subscribe(
                        "/topic/market/order-canceled/" +
                        that.currentCoin.symbol +
                        "/" +
                        that.member.id,
                        function () {
                            // var resp = JSON.parse(msg.body);
                            that.refreshAccount();
                        }
                    );
                    //subscription commission transaction completed
                    stompClient.subscribe(
                        "/topic/market/order-completed/" +
                        that.currentCoin.symbol +
                        "/" +
                        that.member.id,
                        function () {
                            // var resp = JSON.parse(msg.body);
                            that.refreshAccount();
                        }
                    );
                    //subscribe to commission part of the transaction
                    stompClient.subscribe(
                        "/topic/market/order-trade/" +
                        that.currentCoin.symbol +
                        "/" +
                        that.member.id,
                        function () {
                            // var resp = JSON.parse(msg.body);
                            that.refreshAccount();
                        }
                    );
                }

                //subscribe to handicap news
                stompClient.subscribe(
                    "/topic/market/trade-plate/" + that.currentCoin.symbol,
                    function (msg) {
                        var resp = JSON.parse(msg.body);
                        if (resp.direction == "SELL") {
                            var asks = resp.items;
                            that.plate.askRows = [];
                            let totle = 0;
                            for (var i = that.plate.maxPostion - 1; i >= 0; i--) {
                                var ask = {};
                                if (i < asks.length) {
                                    ask = asks[i];
                                } else {
                                    ask["price"] = 0;
                                    ask["amount"] = 0;
                                }
                                ask.direction = "SELL";
                                ask.position = i + 1;
                                that.plate.askRows.push(ask);
                            }
                            for (let i = that.plate.askRows.length - 1; i >= 0; i--) {
                                if (
                                    i == that.plate.askRows.length - 1 ||
                                    that.plate.askRows[i].price == 0
                                ) {
                                    that.plate.askRows[i].totalAmount =
                                        that.plate.askRows[i].amount;
                                } else {
                                    that.plate.askRows[i].totalAmount =
                                        that.plate.askRows[i + 1].totalAmount +
                                        that.plate.askRows[i].amount;
                                }
                                totle += that.plate.askRows[i].amount;
                            }
                            that.plate.askTotle = totle;
                        } else {
                            var bids = resp.items;
                            that.plate.bidRows = [];
                            let totle = 0;
                            for (let i = 0; i < that.plate.maxPostion; i++) {
                                var bid = {};
                                if (i < bids.length) {
                                    bid = bids[i];
                                } else {
                                    bid["price"] = 0;
                                    bid["amount"] = 0;
                                }
                                bid.direction = "BUY";
                                bid.position = i + 1;
                                that.plate.bidRows.push(bid);
                            }
                            for (let i = 0; i < that.plate.bidRows.length; i++) {
                                if (i == 0 || that.plate.bidRows[i].amount == 0) {
                                    that.plate.bidRows[i].totalAmount =
                                        that.plate.bidRows[i].amount;
                                } else {
                                    that.plate.bidRows[i].totalAmount =
                                        that.plate.bidRows[i - 1].totalAmount +
                                        that.plate.bidRows[i].amount;
                                }
                                totle += that.plate.bidRows[i].amount;
                            }
                            that.plate.bidTotle = totle;
                        }
                        if (that.chartStatus === "DEPTH") {
                            that.getPlateFull();
                        }
                    }
                );
            });
        },
        setMarketType(key) {
            this.showMarket = key;
        },
        currentCoinFavorChange() {
            if (!this.isLogin) {
                this.$toast.warning(this.$t("common.loginTip"));
                return;
            }
            if (this.collecRequesting) {
                return;
            }
            const symbol = this.currentCoin.symbol;
            this.collecRequesting = true;
            if (this.currentCoinIsFavor) {
                //favorited go to unfavorite
                this.deleteFavoredMarket({symbol})
                    .then(() => {
                        this.$toast.success(this.$t("exchange.cancel_favorite"));
                        this.getSymbol(); //refresh status
                        this.currentCoinIsFavor = false;
                        this.collecRequesting = false;
                    })
                    .catch((err) => {
                        this.$toast.error(err.message);
                    });
            } else {
                //go to add favorites
                this.addFavoredMarket({symbol})
                    .then(() => {
                        this.$toast.success(this.$t("exchange.do_favorite"));
                        this.getSymbol(); //refresh status
                        this.currentCoinIsFavor = true;
                        this.collecRequesting = false;
                    })
                    .catch((err) => {
                        this.$toast.error(err.message);
                    });
            }
        },
        collect(index, row) {
            if (!this.isLogin) {
                this.$toast.warning(this.$t("common.loginTip"));
                return;
            }
            if (this.coinVisibility === 0) {
                this.$toast.warning(this.$t("exchange.addFavorError"));
                return;
            }
            let payload = {
                symbol: row.symbol,
            };
            this.addFavoredMarket(payload)
                .then(() => {
                    this.$toast.success(this.$t("exchange.do_favorite"));
                    this.getCoin(row.symbol).isFavor = true;
                    row.isFavor = true;
                    this.coins.favor.push(row);
                    if (this.currentCoin.symbol === row.symbol) {
                        this.currentCoinIsFavor = true;
                    }
                })
                .catch((err) => {
                    this.$toast.error(err.message);
                });
        },
        cancelCollect(index, row) {
            if (!this.isLogin) {
                this.$toast.warning(this.$t("common.loginTip"));
                return;
            }
            let payload = {
                symbol: row.symbol,
            };
            this.deleteFavoredMarket(payload)
                .then(() => {
                    this.$toast.success(this.$t("exchange.cancel_favorite"));
                    this.getCoin(row.symbol).isFavor = false;
                    for (var i = 0; i < this.coins.favor.length; i++) {
                        var favorCoin = this.coins.favor[i];
                        if (favorCoin.symbol === row.symbol) {
                            this.coins.favor.splice(i, 1);
                            break;
                        }
                    }
                    if (this.currentCoin.symbol === row.symbol) {
                        this.currentCoinIsFavor = false;
                    }
                })
                .catch((err) => {
                    this.$toast.error(err.message);
                });
        },
        gohref(currentRow) {
            let newHref = currentRow.href;
            this.$router.replace(
                {
                    name: "ExchangePair",
                    params: {
                        pair: currentRow.href,
                    },
                },
                () => {
                }
            );
            if (this.pairParam !== newHref) {
                this.dataTableLoading = true;
                this.dataIndex = [];
                // this.dataIndex2 = [];
            }
        },
        setPlatesPrice(currentRow) {
            this.platesPrice = currentRow.price.toString();
        },
        getWallet() {
            this.getUserWallet(this.currentCoin.base).then((response) => {
                if (response.data.data !== null) {
                    // this.wallet.base = response.data.data.strBalance;
                    this.wallet.base = response.data.data.strBalance;
                } else {
                    this.wallet.base = 0;
                }
            });
            this.getUserWallet(this.currentCoin.coin).then((response) => {
                if (response.data.data !== null) {
                    this.wallet.coin = response.data.data.strBalance;
                } else {
                    this.wallet.coin = 0;
                }
            });
        },
        getCurrentOrder() {
            this.currentOrdersLoading = true;
            //query current order
            let payload = {
                pageNo: 1,
                pageSize: 10,
                symbol: this.currentCoin.symbol,
            };
            this.openOrdersData = [];
            this.getOpenOrders(payload).then((response) => {
                if (response.data.content && response.data.content.length > 0) {
                    this.openOrdersData = response.data.content;
                    this.openOrdersData.forEach((row) => {
                        row.skin = this.skin;
                        row.price =
                            row.type == "MARKET_PRICE"
                                ? this.$t("exchange.marketPrice")
                                : row.price;
                    });
                }
            })
                .finally(() => {
                    this.currentOrdersLoading = false;
                })
        },
        getHistoryOrder(pageNo) {
            this.historyOrdersLoading = true;
            //query historical orders
            if (pageNo == undefined) {
                pageNo = this.historyTablePaginate.page;
            } else {
                pageNo = pageNo - 1;
            }
            this.ordersHistoryData = []; //clear data
            let payload = {
                pageNo: pageNo,
                pageSize: this.historyTablePaginate.pageSize,
                symbol: this.currentCoin.symbol,
            };
            this.getOrdersHistory(payload).then((response) => {
                let rows = [];
                if (response.data.content && response.data.content.length > 0) {
                    this.historyTablePaginate.total = response.data.totalElements;
                    this.historyTablePaginate.page = response.data.number + 1;
                    for (let i = 0; i < response.data.content.length; i++) {
                        let row = response.data.content[i];
                        if (row) {
                            row.skin = this.skin;
                            row.price =
                                row.type == "MARKET_PRICE"
                                    ? this.$t("exchange.marketPrice")
                                    : row.price;
                            rows.push(row);
                        }
                    }
                    this.ordersHistoryData = rows;
                }
            })
                .finally(() => {
                    this.historyOrdersLoading = false;
                })
        },
        getPreconditionsHistory(pageNo) {
            this.preconditionsHistoryLoading = true;
            //query historical orders
            if (pageNo == undefined) {
                pageNo = this.historyTablePaginate.page;
            } else {
                pageNo = pageNo - 1;
            }
            this.preconditionsHistoryData = []; //clear data
            let payload = {
                pageNo: pageNo,
                pageSize: this.historyTablePaginate.pageSize,
                symbol: this.currentCoin.symbol,
            };
            this.getAllPreconditionsHistory(payload).then((response) => {
                let rows = [];
                if (
                    response.data.data.content &&
                    response.data.data.content.length > 0
                ) {
                    this.historyTablePaginate.total = response.data.data.totalElements;
                    this.historyTablePaginate.page = response.data.data.number + 1;
                    for (let i = 0; i < response.data.data.content.length; i++) {
                        let row = response.data.data.content[i];
                        if (row) {
                            row.skin = this.skin;
                            row.price =
                                row.type == "MARKET_PRICE"
                                    ? this.$t("exchange.marketPrice")
                                    : row.price;
                            rows.push(row);
                        }
                    }
                    this.preconditionsHistoryData = rows;
                }
            })
                .finally(() => {
                    this.preconditionsHistoryLoading = false;
                })
        },
        getAllPreconditionOrders(pageNo) {
            this.preconditionOrdersLoading = true;

            if (pageNo === undefined) {
                pageNo = this.stopOrderTablePaginate.page;
            } else {
                pageNo = pageNo - 1;
            }
            this.ordersHistoryData = []; //clear data
            let payload = {
                pageNo: pageNo,
                pageSize: this.stopOrderTablePaginate.pageSize,
                symbol: this.currentCoin.symbol,
            };
            this.getAllActivePreconditions(payload).then((response) => {
                this.stopOrdersData = response.data.data.content;
            })
                .finally(() => {
                    this.preconditionOrdersLoading = false;
                })
        },
        refreshAccount() {
            this.getCurrentOrder();
            this.getHistoryOrder();
            this.getPreconditionsHistory();
            // this.getAllOrderTypesHistoryList();
            this.getAllPreconditionOrders();
            this.getWallet();
        },
        showPublishMask() {
            if (!this.showPublish) {
                this.showPublish = true;
            }
        },
        hidePublishMask() {
            if (this.showPublish) {
                this.showPublish = false;
            }
        },
        hideCountDown() {
            if (this.showCountDown) {
                this.showCountDown = false;
            }
        },
        setPublishProgress() {
        },
        changeTradingViewChart(value) {
            if (this.noBaseCoins) return;
            this.chartsLoading = true;
            this.chartStatus = value;

            if (value === "MARKET") {
                let elem = document.getElementById("tradingViewID");
                if (elem) {
                    document.getElementById("tradingViewID").parentNode.removeChild(elem);
                }
                // this.getKline();
                this.setTradingStatus(value);
            } else if (value === "DEPTH") {
                this.getPlateFull().then(() => {
                    this.chartsLoading = false;
                })
                this.setTradingStatus(value);
            } else {
                this.setTradingStatus(value);
            }
        },
    },
};
